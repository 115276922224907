import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/conseils.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/website.jpg';
import similar2 from '../../assets/img/services/thumbnail/referencement.jpg';
import similar3 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/conseils.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Conseils | Agence digitale | MDS Digital Agency"
      description="Faites appel aux conseils de notre équipe d’experts. Nous définissons ensemble de nouvelles façons de rendre votre site toujours plus rentable."
      keywords="conseils"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Conseils</h2>
      <p>
        Faites appel à notre équipe d’experts pour développer votre{' '}
        <strong>stratégie digitale</strong>.
      </p>

      <p>Nous vous conseillerons dans:</p>
      <ul>
        <li>
          Le choix des <strong>supports de communication</strong>
        </li>
        <li>
          Les <strong>technologies</strong> à utiliser
        </li>
        <li>
          Le <strong>ciblage</strong> de votre clientèle
        </li>
        <li>
          La <strong>performance</strong> de votre site internet
        </li>
        <li>
          L’expérience utilisateur (<strong>UX</strong>)
        </li>
        <li>
          L’amélioration de vos <strong>conversions</strong> en ligne
        </li>
        <li>
          La <strong>visibilité</strong> de votre site web
        </li>
        <li>
          Le retour sur investissement (<strong>ROI</strong>) de vos campagnes{' '}
          <strong>Adwords</strong>
        </li>
        <li>
          La mise en place de <strong>rapports</strong> détaillés
        </li>
      </ul>

      <p>
        Nous définissons ensemble de nouvelles façons de rendre{' '}
        <strong>votre site toujours plus rentable</strong>.
      </p>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Parlez-nous de votre problématique."
        subtitle="Nous sommes à votre écoute !"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar1} />
          <SimilarCard text="Référencement" url="/services/referencement/" img={similar2} />
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar3} />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
